






























































import useEvent from "@/use/event";
import useMisc from "@/use/misc";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OEventAlerts: () =>
      import("@/components/organisms/event/o-event-alerts.vue"),
    MControlList: () => import("@/components/molecules/m-control-list.vue"),
  },
  props: {
    event: Object,
  },

  setup(_, { root }) {
    const {
      getEventTypeColor,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
      getEventTypeName,
    } = useEvent({ root });
    const { getLanguageVersion } = useMisc({ root });

    const state = reactive({
      dialog: false,
    });

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const eventConfig =
      (hasAccessTo("eventConfig.discounts") ||
        hasAccessTo("eventConfig.eventData") ||
        hasAccessTo("eventConfig.graphicOptions") ||
        hasAccessTo("eventConfig.messages") ||
        hasAccessTo("eventConfig.participantGroups")) &&
      hasAccessTo("coordinator");

    return {
      state,
      getEventTypeColor,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
      getEventTypeName,
      hasAccessTo,
      eventConfig,
      getLanguageVersion,
    };
  },
});
